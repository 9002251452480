<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="generateKpoConfirmationForm"
        ref="generateKpoConfirmationForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitKpoConfirmationForm"
      >
        <v-row>
          <v-col cols="12">
            <DateWithTime
              label="Faktyczny czas rozpoczęcia transportu"
              :minutes-step="1"
              :date-with-time="confirmation.realTransportTime"
              @changeTime="confirmation.realTransportTime = $event"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Numer rejestracyjny środka transportu/Rodzaj środka transportu"
              v-model="confirmation.licensePlate"
              :rules="[rules.required]"
              placeholder="Wpisz dodatkowe informacje"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="generateKpoConfirmationForm"
      >
        Wygeneruj potwierdzenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTime from './Inputs/DateWithTime.vue'
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'

export default {
  components: {
    DialogFormWrapper,
    DateWithTime,
  },
  data () {
    return {
      isFormValid: true,
      rules,
      confirmation: {
        realTransportTime: DateTime.now().toISO(),
        licensePlate: null,
      }
    }
  },
  computed: {
    ...mapState({
      bdoCard: state => state.layout.dialog.item,
      isProcessing: state => state.bdoCard.isProcessing,
    })
  },
  mounted () {
    const { finishedAt, driver } = this.bdoCard.order?.bdoCourse || {}
    const { licensePlate } = driver?.vehicle || {}

    if (licensePlate) this.confirmation.licensePlate = licensePlate
    if (finishedAt) this.confirmation.realTransportTime = finishedAt
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      generateKpoConfirmation: 'bdoCard/generateKpoConfirmation',
    }),
    submitKpoConfirmationForm () {
      if (this.$refs.generateKpoConfirmationForm.validate()) {
        this.generateKpoConfirmation(this.confirmation)
          .then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
